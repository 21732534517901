import * as React from "react"
import type { HeadFC } from "gatsby"
import { jsx } from "@emotion/react"

import Main from "../components/Main"
import Header from "../components/Header"

const FAQPage = () => {
  const ethereum = "0x3bf5B46b0767b0f0A1ca2D5fFE5916975994DA54"
  const arbitrum = "0xEBdc02713EdF14a4c1e67813AF7654d146BC67BE"
  const optimism = "0x7737a35B165AC1Ff1fe0E5B215F9885b9C67c2b0"

  return (
    <>
      <Header />
      <Main css={{color: "#fff" }}>
        <div css={{
          margin: "0 auto",
          maxWidth: 1024,
          lineHeight: 1.6,
          fontSize: 24,
          letterSpacing: 1,
          "& h3": {
            fontSize: 32
          },
          "& p": {
            margin: 0,
          },
          "& a": {
            color: "#FFF",
            textDecoration: "none",
          }
          }}>
          <h3>What exactly is Crydeal?</h3>
          <p> Crydeal is a locking-to-trading protocol. </p>
          <br />
          <p> Crydeal uses smart contract to establish trust between parties, in order to avoid fraud and dishonesty when buying and selling goods and services. </p>
          <br />
          <p> The main principle of Crydeal is to create a lock-up program through a smart contract, where both parties are required to deposit a certain amount of tokens as collateral. </p> 
          <br />
          <p> Once the deal is completed and both parties are satisfied, the collateral will be unlocked and returned to the parties. </p> 
          <br />
          <p> This mechanism ensures the honest behavior of both parties in the transaction, without relying on intermediaries or third-party services. </p>
          <br />
          <p> The advantages of Crydeal include: </p>
          <ol>
            <li> Trustless: Through smart contracts and a lock-up program jointly controlled by both parties, there is no need to trust each other or any third-party services. </li>
            <li> Privacy protection: Personal information is known only to those involved in the deal. </li>
            <li> Lower costs: Since there is no third-party involvement, fee costs may be reduced. </li>
            <li> Controllable risk: Both parties can adjust the lock-up amount as needed, increasing the cost of default for the other party. </li>
          </ol>
          <br />
          <br />
          <h3>No Fee?</h3>
          <p> Yes, smart contract won't charge fees. </p>
          <br />
          <br />
          <h3>Contract Opensource? </h3>
          <p>Yes</p>
          <br />
          <br />
          <h3>Contract Address</h3>
          <code>
            <a href={`https://etherscan.io/address/${ethereum}`} target="_blank"> {ethereum} </a> (Ethereum)
            <a href={`https://arbiscan.io/address/${arbitrum}`} target="_blank"> {arbitrum} </a> (Arbitrum)
            <a href={`https://optimistic.etherscan.io/address/${optimism}`} target="_blank"> {optimism} </a> (Optimism)
          </code>
          <br />
          <br />
          <h3>Frontend Code Opensource?</h3>
          <p> Yes, when the development work is completed. </p>
        </div>
        <div css={{ height: 100 }} />
      </Main>
    </>
  )
}

export default FAQPage

export const Head: HeadFC = () => <title>Crydeal - FAQ</title>
